<template>
  <v-container fluid>
    <v-card min-height="370px" class="pt-4 px-2">
      <v-tabs center-active centered v-model="selectedTab">
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <template v-for="(tab,i) in settingTabs">
        <v-tab :key="i" v-if="tab.show">
          <span class="subtitle-1">{{ tab.text }}</span>
        </v-tab>
        </template>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" class="mt-8 tab-item-center">
        <!-- 公司认证 -->
        <v-tab-item v-if="$root.authority == 'ROLE_USER'">
          <v-form ref="companyCertForm">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 3">{{$t('lang.setting.certificationStatus')}}</v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 9">
              <span v-if="companyCertForm.status == 'PASS'" class="success--text">{{$t('lang.user.verified')}}</span>
              <span v-else-if="companyCertForm.status == 'REFUSE'" class="error--text">{{$t('lang.user.refuseCertified')}}</span>
              <span v-else-if="companyCertForm.status == 'CHECKING'">{{$t('lang.user.checkingCertified')}}</span>
              <span v-else>{{$t('lang.user.notCertified')}}</span>
            </v-col>
          </v-row>    
          <v-row>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 3">{{$t('lang.register.businessLicense')}}</v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 9">
              <v-sheet
                min-height="150"
                max-width="220"
                class="grey lighten-4 d-flex align-center justify-center"
              >
                <v-file-input
                  hide-input
                  accept="image/*"
                  prepend-icon="mdi-plus-circle"
                  class="pt-0 mt-0 justify-center"
                  style="z-index:1"
                  v-model="companyCertForm.businessLicensePhoto"
                  :rules="certificationRules"
                  @change="uploadBusinessLicensePhoto()"
                ></v-file-input>
                <v-img
                  :src="businessLicensePhotoUrl"
                  max-height="85"
                  max-width="85"
                  style="position:absolute;"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="companyCertification()">{{$t('lang.setting.submitBtn')}}</v-btn>
          </v-card-actions>
        </v-tab-item>
        
        <!-- 学生认证 -->
        <v-tab-item v-if="$root.authority == 'ROLE_USER'">
          <v-form ref="studentCertForm">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 3">{{$t('lang.setting.certificationStatus')}}</v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 9">
              <span v-if="studentCertForm.status == 'PASS'" class="success--text">{{$t('lang.user.verified')}}</span>
              <span v-else-if="studentCertForm.status == 'REFUSE'" class="error--text">{{$t('lang.user.refuseCertified')}}</span>
              <span v-else-if="studentCertForm.status == 'CHECKING'">{{$t('lang.user.checkingCertified')}}</span>
              <span v-else>{{$t('lang.user.notCertified')}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 3">{{$t('lang.setting.studentIdPhoto')}}</v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 9">
              <v-sheet
                min-height="150"
                max-width="220"
                class="grey lighten-4 d-flex align-center justify-center"
              >
                <v-file-input
                  hide-input
                  accept="image/*"
                  prepend-icon="mdi-plus-circle"
                  class="pt-0 mt-0 justify-center"
                  style="z-index:1"
                  v-model="studentCertForm.studentPhoto"
                  :rules="certificationRules"
                  @change="uploadStudentPhoto()"
                ></v-file-input>
                <v-img
                  :src="studentPhotoUrl"
                  max-height="85"
                  max-width="85"
                  style="position:absolute;"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="studentCertification()">{{$t('lang.setting.submitBtn')}}</v-btn>
          </v-card-actions>
        </v-tab-item>

        <!-- <v-tab-item v-if="$root.authority != 'ROLE_ADMIN'"> -->
          <!-- 邮箱 项目/财务管理员有 -->
          <!-- <v-row dense v-if="$root.authority == 'ROLE_USER_ADMIN' || $root.authority == 'ROLE_FINANCIAL'">
            <v-col cols="2">{{$t('lang.user.email')}}</v-col>
            <v-col cols="9">
              <v-form ref="emailForm">
              <v-text-field solo dense v-model="emailForm.email" :rules="emailRules">
                 <template v-slot:append-outer>
                  <v-btn small class="primary" @click="submitEmail()">{{$t('lang.setting.submitBtn')}}</v-btn>
                </template>
              </v-text-field>
              </v-form>
            </v-col>
          </v-row> -->
          <!-- 电话号码 普通用户有 -->
          <!-- <v-form ref="phoneForm" v-if="$root.authority == 'ROLE_USER'">
          <v-row dense>
            <v-col cols="2">{{$t('lang.register.phoneNumberLabel')}}</v-col>
            <v-col cols="9">
              <v-text-field solo dense v-model="phoneForm.phoneNumber" :rules="phoneNumberRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">{{$t('lang.register.verificationCodeLabel')}}</v-col>
            <v-col cols="9">
              <v-text-field solo dense v-model="phoneForm.verificationCode" :rules="verificationCodeRules">
                <template v-slot:append-outer>
                  <v-btn small @click="getVerificationCode()" :disabled="verificationCodeBtnAble">{{ verificationCodeBtnAble ? verificationCodeBtnlabel : $t('lang.register.verificationCodeBtn') }}</v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="submitPhoneNumber()">{{$t('lang.setting.submitBtn')}}</v-btn>
          </v-card-actions>
           </v-form>
        </v-tab-item> -->
        
        <!-- 修改密码 -->
        <v-tab-item>
          <v-form ref="passwordForm">
          <!-- <v-row dense>
            <v-col cols="2">{{$t('lang.setting.oldPassword')}}</v-col>
            <v-col cols="10">
              <v-text-field solo dense type="password" v-model="passwordForm.oldPassword" :rules="oldPasswordRules"></v-text-field>
            </v-col>
          </v-row> -->
          <v-row dense>
            <v-col cols="2">{{$t('lang.setting.newPassword')}}</v-col>
            <v-col cols="10">
              <v-text-field solo dense type="password" v-model="passwordForm.newPassword" :rules="newPasswordRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">{{$t('lang.setting.confirmPassword')}}</v-col>
            <v-col cols="10">
              <v-text-field solo dense type="password" v-model="passwordForm.confirmPassword" :rules="confirmPasswordRules"></v-text-field>
            </v-col>
          </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="changePassword()">{{$t('lang.setting.submitBtn')}}</v-btn>
          </v-card-actions>
        </v-tab-item>
       
      </v-tabs-items>
    </v-card>
    <v-snackbar :timeout="tips.timeout" v-model="tips.showSnackbar" :color="tips.snackbarColor" :top="true">{{ tips.snackbarText }}</v-snackbar>
  </v-container>
</template>
<script>
import axios from 'axios';
import qs from 'qs'
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl;
let vm;
export default {
  data() {
    return {
      selectedTab: null,
      settingTabs: [
        { text: this.$t('lang.user.companyCertification'), show: this.$root.authority == 'ROLE_USER' },
        { text: this.$t('lang.user.studentCertification'), show: this.$root.authority == 'ROLE_USER' },
        // { text: this.$t('lang.setting.basicInformation'), show: this.$root.authority != 'ROLE_ADMIN' },
        { text: this.$t('lang.setting.changePassword'), show: true},
      ],
      businessLicensePhotoUrl: require("../../public/logo.png"),
      studentPhotoUrl: require("../../public/logo.png"),
      // userInfo: {
      //   type: 'naturalPerson',//company
      //   certification: 'notCertified'//verified
      // },
      emailForm: {
        email: ''
      },
      emailRules: [
        v => !!v || this.$t('lang.user.emailRules.required'),
        v => /.+@.+\..+/.test(v) || this.$t('lang.user.emailRules.valid'),
      ],
      verificationCodeBtnAble: false,
      verificationCodeBtnlabel: '',
      phoneNumberRules: [
        v => !!v || this.$t('lang.register.phoneNumberRules.required'),
        v => /^1[3456789]\d{9}$/.test(v) || this.$t('lang.register.phoneNumberRules.formatValidation')
      ],
      verificationCodeRules: [
        v => !!v ||  this.$t('lang.register.verificationCodeRules.required'),
      ],
      phoneForm: {
        phoneNumber: '',
        verificationCode: ''
      },
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      oldPasswordRules: [
        v => !!v || this.$t('lang.setting.oldPasswordRules.required'),
      ],
      newPasswordRules: [
        v => !!v || this.$t('lang.setting.newPasswordRules.required'),
        // v => v.length >= 16 || this.$t('lang.setting.newPasswordRules.minimumCharacterLimit'),
        // v => v.length <= 25 || this.$t('lang.setting.newPasswordRules.maximumCharacterLimit'),
        // v => /^(?=.*[0-9])(?=.*[a-zA-Z])(.{16,25})$/.test(v) || this.$t('lang.setting.newPasswordRules.specialCharacterRestriction'),
      ],
      confirmPasswordRules: [
        v => !!v || this.$t('lang.register.confirmPwdRules.required'),
        // v => v.length >= 16 || this.$t('lang.register.confirmPwdRules.minimumCharacterLimit'),
        // v => v.length <= 25 || this.$t('lang.register.confirmPwdRules.maximumCharacterLimit'),
        // v => /^(?=.*[0-9])(?=.*[a-zA-Z])(.{16,25})$/.test(v) || this.$t('lang.register.confirmPwdRules.specialCharacterRestriction'),
        v => v == this.passwordForm.newPassword || this.$t('lang.register.confirmPwdRules.passwordConsistency')
      ],
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1
      },
      companyCertForm: {
        businessLicensePhoto: [],
        status: 'default'
      },
      certificationRules: [
        v =>  v.size > 0 || "required"
      ],
      studentCertForm: {
        studentPhoto: [],
        status: 'default'
      }
    };
  },
  created() {
    vm = this;
    if(this.$root.authority == 'ROLE_USER') this.getUserApplyInfo()
  },
  methods: {
    // submitEmail() {
    //   if(!this.$refs.emailForm.validate()) return
    // },
    //通知提示工具
    showTips (text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if(timeout) {
        this.tips.timeout = timeout
      }else{
        this.tips.timeout = 3000
      }
    },
    // getVerificationCode() {
    //   if(!this.phoneForm.phoneNumber){
    //     this.showTips(this.$t('lang.register.phoneNumberRules.required'), 'error')
    //     return
    //   } 
    //   //点击获取验证码后 60s后才能再次获取
    //   this.verificationCodeBtnAble = true
    //   let seconds = 60
    //   this.verificationCodeBtnlabel = `${this.$t('lang.register.resendverificationCode')}(${seconds})`
    //   let interval =  setInterval(() =>{
    //     seconds -= 1
    //     if(seconds <= 0){
    //       vm.verificationCodeBtnAble = false
    //       clearInterval(interval)
    //     }
    //     vm.verificationCodeBtnlabel = `${this.$t('lang.register.resendverificationCode')}(${seconds})`
    //   }, 1000)
    //},
    // submitPhoneNumber() {
    //   if(!this.$refs.phoneForm.validate()) return
    // },
    changePassword() {
      if(!this.$refs.passwordForm.validate()) return
      axios.post(`${apiUrl}changePassword`, qs.stringify({
        newPassword: this.passwordForm.newPassword
      })).then(res => {
        if(res.status == 200) {
          vm.showTips(this.$t('lang.tips.modifySuccess'), 'success')
          setTimeout(() => {
            vm.$router.push("/login");
          }, 3000)
          return
        }
        vm.showTips(this.$t('lang.tips.modifyFailed'), 'error')
      }).catch(error => {
        console.log(error)
        vm.showTips(this.$t('lang.tips.modifyFailed'), 'error')
      })
    },
    //上传营业执照
    uploadBusinessLicensePhoto () {
      if (this.companyCertForm.businessLicensePhoto.size > 1024 * 1024 * 2) {
        this.companyCertForm.businessLicensePhoto = []
        this.showTips(this.$t('lang.setting.fileLimit'), 'error')
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.companyCertForm.businessLicensePhoto);
      //console.log(this.companyCertForm.businessLicensePhoto)
      reader.onload = function () {
        //console.log(reader.result)
        //直接使用读取的结果
        vm.businessLicensePhotoUrl = reader.result;
      };
    },
    //公司认证
    companyCertification() {
      if(!this.$refs.companyCertForm.validate()) {
        this.showTips(this.$t('lang.setting.photoRequired'), 'error')
        return
      }
      // let photoFormData = new FormData();
      // photoFormData.append('certificationPhoto', this.companyCertForm.businessLicensePhoto)
      // let paramFormData = new FormData()
      // paramFormData.append('attributes', photoFormData)
      // paramFormData.append('type', 'BUSINESS')
      let attributesObj = {
        //certificationPhoto: this.companyCertForm.businessLicensePhoto
        base64Photo: this.businessLicensePhotoUrl
      }
      let param = {
        attributes: attributesObj,
        type: 'BUSINESS'
      }
      console.log(param)
      this.editUserApply(param)
    },
    editUserApply(param) {
      axios.post(`${apiUrl}user/apply/edit`, param).then(res => {
        if (res.status == 200) {
          this.showTips(vm.$t("lang.tips.submitSuccess"), "success");
          return;
        }
        this.showTips(vm.$t("lang.tips.submitFailed"), "error");
      }).catch(error => {
         console.log(error)
         this.showTips(vm.$t("lang.tips.submitFailed"), "error");
      })
    },
    getUserApplyInfo() {
      axios.get(`${apiUrl}user/apply/get`).then(res => {
        if(res.status == 200) {
          if(!res.data) return
          if(res.data.type == 'BUSINESS') {
            vm.businessLicensePhotoUrl = res.data.attributes.base64Photo
            vm.companyCertForm.status = res.data.status
          }else {
            vm.studentPhotoUrl = res.data.attributes.base64Photo
            vm.studentCertForm.status = res.data.status
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    //上传学生证照片
    uploadStudentPhoto() {
      if (this.studentCertForm.studentPhoto.size > 1024 * 1024 * 2) {
        this.studentCertForm.studentPhoto = []
        this.showTips(this.$t('lang.setting.fileLimit'), 'error')
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.studentCertForm.studentPhoto);
      reader.onload = function () {
        vm.studentPhotoUrl = reader.result;
      };
    },
    //学生认证
    studentCertification() {
      if(!this.$refs.studentCertForm.validate()) {
        this.showTips(this.$t('lang.setting.photoRequired'), 'error')
        return
      }
      let attributesObj = {
        base64Photo: this.studentPhotoUrl
      }
      let param = {
        attributes: attributesObj,
        type: 'STUDENT'
      }
      console.log(param)
      this.editUserApply(param)
    }
      
  }
};
</script>
<style scoped>
.tab-item-center {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
</style>>
